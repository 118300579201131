import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Switch, type SwitchChangeEvent } from "@progress/kendo-react-inputs";

import { FullHeightSpinner } from "../../shared/FullHeightSpinner/FullHeightSpinner";
import type { RootState } from "../../../store/reducers/rootReducer";
import type { SuppliersType } from "../../../interfaces/auddineceAccessInterfaces/batchOverviewTypes";
import { fetchGetJsonData, fetchPutJsonData } from "../../../services/services";
import { setBatch } from "../../../store/reducers/batchReducer/batchReducer";
import { useParams } from "react-router-dom";


export const Suppliers = () => {
  const batchData = useSelector((state: RootState) => state.batchDataReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { customerId } = useSelector((state: RootState) => state.audienceAdminReducer);

  const dispatch = useDispatch();
  const params: { projectId: string, audienceId: string, sampleId: string, batchId: string } = useParams();
  const { projectId, sampleId, audienceId, batchId } = params;

  const [suppliers, setSuppliers] = useState<SuppliersType[] | null>(null);
  const [selectedSuppliers, setSelectedSuppliers] = useState<string[]>(batchData.integrationPartners ? batchData.integrationPartners : []);
  const [isLoading, setIsLoading] = useState(true);
  const [submittingSuppliers, setSubmittingSuppliers] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const integrationPartnersUrl = `au/a/integrationpartners?countrycode=${ batchData?.market.country }&languagecode=${ batchData?.market.language }`;
    fetchGetJsonData<SuppliersType[]>({ url: integrationPartnersUrl, token: token, baseUrl: "audience", customerId: customerId })
      .then((res) => {
        setSuppliers(res);
        setIsLoading(false);
      }).catch((error) => {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } });
        setIsLoading(false);
      })
  }, [token, batchData, dispatch]);

  const handleSelectSupplier = (event: SwitchChangeEvent) => {
    const inputValue = event.target.props.value as string;
    if (event.value) {
      setSelectedSuppliers(selectedSuppliers.concat(inputValue))
    } else {
      const filteredSelectedSuppliers = selectedSuppliers.filter((item) => item !== inputValue);
      setSelectedSuppliers(filteredSelectedSuppliers);
    }
  }

  const updateSuppliers = () => {
    setSubmittingSuppliers(true);
    const body = {
      etag: batchData.etag,
      integrationPartners: selectedSuppliers
    }

    fetchPutJsonData<{ etag: string }>({ url: `au/a/projects/${projectId}/samples/${sampleId}/audiences/${audienceId}/batches/${batchId}/integrationpartners`, token: token, body: body, baseUrl: "audience", customerId: customerId })
      .then((res) => {
        dispatch(setBatch({ ...batchData, integrationPartners: selectedSuppliers, etag: res.etag }));
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Suppliers updated" } });
        setSubmittingSuppliers(false);
      }).catch((error) => {
        setSelectedSuppliers(batchData.integrationPartners);
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } });
        setSubmittingSuppliers(false);
      })
  }

  return (
    <article className='nav-board bg-white d-flex justify-content-center flex-column w-100 mb-0 p-5 gap-lg'>
      <div className="d-flex flex-column">
        <p className="stronger m-0" style={{ fontSize: "1rem" }}>
          Integrated suppliers
        </p>
        <p className="m-0">
          If suppliers are not in the list below, please contact them directly and use the link builder to send them the appropriate links
        </p>
      </div>

      <div className="d-flex flex-column gap-md">
        {
          isLoading ?
            <FullHeightSpinner />
            :
            <Fragment>
              {suppliers ?
                suppliers.map((supplier) =>
                  <div key={supplier.id} className="d-flex gap-md">
                    <p className="m-0" style={{ width: "150px" }}>{supplier.name}</p>
                    <Switch
                      name="switch-checkbox"
                      value={supplier.id}
                      checked={selectedSuppliers ? selectedSuppliers?.includes(supplier.id) : false}
                      onChange={(e) => handleSelectSupplier(e)}
                      size="small"
                    />
                  </div>
                )
                :
                <div className="d-flex gap-md">
                  <p>No suppliers</p>
                </div>
              }
            </Fragment>
        }
      </div>

      <input name="integrationPartners" readOnly hidden value={JSON.stringify(selectedSuppliers)} />

      <div className="d-flex justify-content-end">
        <button type="button" className="btn btn-primary" disabled={submittingSuppliers} onClick={() => updateSuppliers()} style={{ width: "fit-content" }}>
          Update suppliers
          {
            submittingSuppliers &&
            <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" />
          }
        </button>
      </div>
    </article>
  )
}
