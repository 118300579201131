// This was initially a copy of src/components/Gatekeeper/GKNewReconciliationModal/GKNewReconciliationModal.tsx

import type React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { fetchPostResOrJson } from '../../../services/services';
import {  useSelector } from "react-redux";
import type { RootState } from "../../../store/reducers/rootReducer";

interface ModalData {
  respondentIds: string[],
  name?: string | undefined
}
interface Props {
  // biome-ignore lint/complexity/noBannedTypes: <explanation>
  onHide: Function,
  // biome-ignore lint/complexity/noBannedTypes: <explanation>
  handlePreview?: Function,
  modalData: ModalData,
  // biome-ignore lint/complexity/noBannedTypes: <explanation>
  setModalData: Function,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  error: any,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  setError: any
}

export const ReconciliationModal = ({ onHide, setModalData, modalData, error, setError  }: Props) => {

  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    setModalData({ ...modalData, [e.target.name]: e.target.name === "respondentIds" ? e.target.value.split('\n') : e.target.value })
  }

  const sendAndHandleResponse = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (handleErrorNewObject(modalData)) {
      fetchPostResOrJson("au/r/reconcile", token, modalData)
        .then(async () => {
          try {
            setModalData({
              respondentIds: [],
              name: ""
            })
            handleErrorNewObject(modalData);
          } catch (err) {
            console.log('err:', err);
          }
          onHide("Reconciliation Preview")
        })
        .catch((err: Error) => {
          console.error('Fetch error:', err);
        });
    }
  };

  const handleErrorNewObject = (obj: ModalData) => {
    let emptyFieldError = {}
    if (obj.respondentIds.length < 1) {
      emptyFieldError = Object.assign({ ...emptyFieldError, respondentIds: true })
    }
    if (obj.name === "") {
      emptyFieldError = Object.assign({ ...emptyFieldError, name: true })
    }
    if (Object.keys(emptyFieldError).length > 0) {
      setError({ ...emptyFieldError })
      return false
    }
    return true
  }

  return (
    <Dialog width={"35%"} className='reconcile-modal' contentStyle={{ padding: 0, fontSize: 14 }} title={"New reconciliation"} onClose={() => onHide("Reconciliation")}>
      <form onSubmit={(e) => sendAndHandleResponse(e)} >
        <div className='pt-3 px-4' >
          <p className='p-0 m-0 text-start' ><label className='p-0 m-0 medium strong' htmlFor="reconcileModal">Walr IDs</label></p>
          <p className='p-0 m-0 text-start text-muted'>
            <label className='medium m-0' htmlFor="reconcileModal">
              Paste the <strong>accepted</strong> Walr IDs in the box below. All other IDs will be marked as invalid completes. Please note that pasting at least one Walr ID from an Audience will overwrite the reconciliation for that Audience!
            </label>
          </p>
          <div className="d-flex flex-column justify-content-center w-100 py-3">
            <div className="details-input d-flex p-0 flex-column w-100 pb-2 ">
              <label htmlFor='name' className='p-0 m-0 strong text-primary'>Reconciliation name</label>
              <input className='form-control medium' type="text" name='name' placeholder='Enter name' value={modalData.name} onChange={(e) => handleChange(e)} />
              {error?.name &&
                <div className="text-danger">
                  <small>This field is required.</small>
                </div>}
            </div>
            <textarea
              className='w-100 form-control'
              id="reconcileModal"
              name="respondentIds"
              placeholder='Add IDs one per line'
              rows={5}
              cols={50}
              value={modalData.respondentIds.join('\n')}
              onChange={(e) => handleChange(e)}>
            </textarea>
            {error?.respondentIds &&
              <div className="text-danger">
                <small>This field is required.</small>
              </div>}
          </div>
        </div>
        <DialogActionsBar>
          <div className='d-flex'>
            <button type='submit' className="btn btn-secondary mr-2" onClick={() => onHide("Reconciliation Preview")}>Back</button>
            <button
              type='submit' className="btn btn-primary">Reconcile</button>
          </div>
        </DialogActionsBar>
      </form>
    </Dialog >
  )
}
